<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="form-group">
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tablePending.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onCreate"
          >
            Create Request
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="tablePending"
        hover
        responsive
        class="mt-2"
        :per-page="tablePending.perPage"
        :current-page="tablePending.currentPage"
        :items="tableProvider"
        :fields="tablePending.fields"
        :sort-by.sync="tablePending.sortBy"
        :sort-desc.sync="tablePending.sortDesc"
        :sort-direction="tablePending.sortDirection"
        :filter="tablePending.filter"
        :filter-included-fields="tablePending.filterOn"
        show-empty
      >

        <template #cell(index)="data">
          {{ tablePending.currentPage * tablePending.perPage - tablePending.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div
            class="text-nowrap"
          >
            <b-button
              size="sm"
              class="mr-1"
              @click="onView(row.item)"
            >
              View
            </b-button>
            <b-button
              size="sm"
              variant="outline-danger"
              @click="onViewCancellation(row.item)"
            >
              Cancel
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tablePending.perPage"
              :options="tablePending.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tablePending.currentPage"
            :total-rows="tablePending.totalRows"
            :per-page="tablePending.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal new request -->
    <b-modal
      id="modal-maintenance-form"
      size="xl"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="modalTitle"
      @ok="onValidate"
    >
      <ValidationObserver ref="formMaintenance">
        <form
          novalidate
          role="formMaintenance"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="requestor name"
                vid="requestor_name"
                rules="required"
              >
                <b-form-group>
                  <label for="requestor_name">
                    <strong>Requestor Name</strong>
                  </label>
                  <b-input
                    id="requestor_name"
                    v-model="maintenance.requestor_name"
                    type="text"
                    placeholder="enter requestor"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="estimated amount"
                vid="estimated_amount"
                rules="required|double"
              >
                <b-form-group>
                  <label for="estimated_amount">
                    <strong>Estimated Amount</strong>
                  </label>
                  <b-input
                    id="estimated_amount"
                    v-model="maintenance.estimated_amount"
                    type="number"
                    placeholder="enter estimated amount"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="funding_type"
                vid="funding_type"
                rules="required"
              >
                <b-form-group>
                  <label for="funding_type">
                    <strong>Funding Type</strong>
                  </label>
                  <b-input
                    id="funding_type"
                    v-model="fundingTypeValue"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="vehicle plate number"
                vid="vehicle"
                rules="required"
              >
                <b-form-group>
                  <label for="license_plate">
                    <strong>Vehicle Plate Number</strong>
                  </label>
                  <v-select
                    id="license_plate"
                    v-model="selected.vehicle"
                    type="text"
                    label="license_plate"
                    placeholder="select vehicle plate number here"
                    :options="list.vehicles"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  >
                    <template #option="{ name, license_plate }">
                      <div class="d-flex flex-column">
                        <span>{{ license_plate }}</span>
                        <strong>{{ name }}</strong>
                      </div>
                    </template>
                    <template #no-options="">
                      No Available Vehicle
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="vehicle_name">
                  <strong>Vehicle Name</strong>
                </label>
                <b-input
                  id="vehicle_name"
                  v-model="maintenance.vehicle_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="last odometer"
                vid="last_odometer"
                rules="required|integer"
              >
                <b-form-group>
                  <label for="last_odometer">
                    <strong>Last Odometer</strong>
                  </label>
                  <b-input
                    id="last_odometer"
                    v-model="maintenance.last_odometer"
                    type="number"
                    placeholder="enter last odometer"
                    autocomplete="off"
                    disabled
                    :state="errors.length > 0 ? false : null"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="current odometer"
                vid="current_odometer"
                :rules="`required|integer|min_value:${maintenance.last_odometer}`"
              >
                <b-form-group>
                  <label for="current_odometer">
                    <strong>Current Odometer</strong>
                  </label>
                  <b-input
                    id="current_odometer"
                    v-model="maintenance.current_odometer"
                    type="number"
                    placeholder="enter odometer"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="damage item specification"
                vid="damaged_item_specifications"
                rules="required"
              >
                <b-form-group>
                  <label for="damaged_item_specifications">
                    <strong>Ano ang sira? ( Sabihin ng Maayos )</strong>
                  </label>
                  <b-textarea
                    id="damaged_item_specifications"
                    v-model="maintenance.damaged_item_specifications"
                    rows="6"
                    max-rows="8"
                    placeholder="sabihin ng maayos kung ano ang sira"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="cause of damage"
                vid="cause_of_damage"
                rules="required"
              >
                <b-form-group>
                  <label for="cause_of_damage">
                    <strong>Rason ng pagkasira? ( Sabihin ng Maayos )</strong>
                  </label>
                  <b-textarea
                    id="cause_of_damage"
                    v-model="maintenance.cause_of_damage"
                    rows="6"
                    max-rows="8"
                    placeholder="sabihin ng maayos ang rason ng pagkasira"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row
            v-if="!state.viewing"
          >
            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="attachments">
                  <strong>Attachments</strong>
                </label>
                <b-form-file
                  id="attachments"
                  v-model="attachment.files"
                  accept=".jpg, .jpeg, .png"
                  multiple
                  :disabled="state.busy"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-3"
            >
              <b-button
                v-if="attachment.files.length > 0"
                size="sm"
                variant="success"
                class="mr-1"
                :disabled="state.busy"
                @click="onUploadAttachment"
              >
                Upload Files
              </b-button>
              <b-button
                v-if="maintenance.attachments.length > 0"
                size="sm"
                variant="danger"
                :disabled="state.busy"
                @click="onRemoveAllFile"
              >
                Remove Files
              </b-button>
            </b-col>
          </b-row>
          <b-table
            ref="attachment_table"
            :fields="tableAttachments.fields"
            :items="maintenance.attachments"
            responsive
            striped
            hover
          >
            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  class="mr-1"
                  :disabled="state.busy"
                  @click="onPreviewAttachment(row.item)"
                >
                  Preview
                </b-button>
                <b-button
                  v-if="!state.viewing"
                  variant="danger"
                  size="sm"
                  :disabled="state.busy"
                  @click="onRemoveFile(row.item)"
                >
                  Remove
                </b-button>
              </div>
            </template>
          </b-table>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-if="!state.viewing"
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          Submit Request
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-maintenance-view-form"
      size="xl"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      title="Cancel Request"
      @ok="onValidateCancellation"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <ValidationObserver
            ref="formCancellation"
          >
            <form
              novalidate
              role="form"
              @submit.prevent
            >
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="cancellation_remarks"
                rules="required"
              >
                <b-form-group>
                  <label for="cancellation_remarks">
                    <strong>Cancellation Remarks</strong>
                  </label>
                  <b-textarea
                    id="cancellation_remarks"
                    v-model="maintenance.cancellation_remarks"
                    rows="6"
                    max-rows="8"
                    placeholder="enter your remarks"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="requestor_name_view">
              <strong>Requestor By</strong>
            </label>
            <b-input
              id="requestor_name_view"
              v-model="maintenance.requestor_name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="amount_view">
              <strong>Amount</strong>
            </label>
            <b-input
              id="amount_view"
              v-model="maintenance.estimated_amount"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="funding_type_view">
              <strong>Funding Type</strong>
            </label>
            <b-input
              id="funding_type_view"
              v-model="fundingTypeValue"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="company_name_view">
              <strong>Company</strong>
            </label>
            <b-input
              id="company_name_view"
              v-model="maintenance.company_name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="organization_name_view">
              <strong>Organization</strong>
            </label>
            <b-input
              id="organization_name_view"
              v-model="maintenance.organization_name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="vehicle_license_plate_view">
              <strong>Vehicle Plate Number</strong>
            </label>
            <b-input
              id="vehicle_license_plate_view"
              v-model="maintenance.vehicle_license_plate"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="vehicle_name_view">
              <strong>Vehicle Name</strong>
            </label>
            <b-input
              id="vehicle_name_view"
              v-model="maintenance.vehicle_name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="vehicle_category_name_view">
              <strong>Vehicle Category</strong>
            </label>
            <b-input
              id="vehicle_category_name_view"
              v-model="maintenance.vehicle_category_name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="vehicle_type_view">
              <strong>Vehicle Type</strong>
            </label>
            <b-input
              id="vehicle_type_view"
              v-model="vehicleIsValue"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="noted_by_at">
              <strong>Last Odometer</strong>
            </label>
            <b-input
              id="noted_by_at"
              v-model="maintenance.last_odometer"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="current_odometer">
              <strong>Current Odometer</strong>
            </label>
            <b-input
              id="current_odometer"
              v-model="maintenance.current_odometer"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="damaged_item_specifications_view">
              <strong>Ano ang Nasira?</strong>
            </label>
            <b-textarea
              id="damaged_item_specifications_view"
              v-model="maintenance.damaged_item_specifications"
              rows="6"
              max-rows="8"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="cause_of_damage_view">
              <strong>Rason ng Pagkasira?</strong>
            </label>
            <b-textarea
              id="cause_of_damage_view"
              v-model="maintenance.cause_of_damage"
              rows="6"
              max-rows="8"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <label><strong>Attachments</strong></label>
          <b-table
            class="mt-1"
            :fields="tableAttachments.fields"
            :items="maintenance.attachments"
            responsive
            striped
            hover
          >
            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  class="mr-1"
                  :disabled="state.busy"
                  @click="onPreviewAttachment(row.item)"
                >
                  Preview
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="ok()"
        >
          Marked as Canceled
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- Modal View Form -->
    <b-modal
      id="modal-view-form"
      size="xl"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      title="View Repair / Maintenance Request"
      aria-hidden="false"
    >
      <b-card no-body>
        <b-row>

          <!-- Requester -->
          <b-col
            cols="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="created_at">
                    <strong>Requested At</strong>
                  </label>
                  <b-input
                    id="created_at"
                    v-model="maintenanceTransaction.created_at"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="requested_by">
                    <strong>Requested By</strong>
                  </label>
                  <b-input
                    id="requested_by"
                    v-model="maintenanceTransaction.requestor_name"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Company & Organizations -->
          <b-col
            cols="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="company_name">
                    <strong>Company</strong>
                  </label>
                  <b-input
                    id="company_name"
                    v-model="maintenanceTransaction.company_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="organization_name">
                    <strong>Organization</strong>
                  </label>
                  <b-input
                    id="organization_name"
                    v-model="maintenanceTransaction.organization_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Fund Type & Amounts -->
          <b-col
            cols="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group>
                  <label for="amount">
                    <strong>Estimated Amount</strong>
                  </label>
                  <b-input
                    id="amount"
                    v-model="maintenanceTransaction.estimated_amount"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group>
                  <label for="actual_amount">
                    <strong>Actual Amount</strong>
                  </label>
                  <b-input
                    id="actual_amount"
                    v-model="maintenanceTransaction.actual_amount"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="funding_type">
                    <strong>Funding Type</strong>
                  </label>
                  <b-input
                    id="funding_type"
                    v-model="fundingTypeTransactionValue"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Vehicle -->
          <b-col
            cols="12"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="vehicle_license_plate">
                    <strong>Vehicle Plate Number</strong>
                  </label>
                  <b-input
                    id="vehicle_license_plate"
                    v-model="maintenanceTransaction.vehicle_license_plate"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="vehicle_name">
                    <strong>Vehicle Name</strong>
                  </label>
                  <b-input
                    id="vehicle_name"
                    v-model="maintenanceTransaction.vehicle_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="vehicle_category_name">
                    <strong>Vehicle Category</strong>
                  </label>
                  <b-input
                    id="vehicle_category_name"
                    v-model="maintenanceTransaction.vehicle_category_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="vehicle_type">
                    <strong>Vehicle Type</strong>
                  </label>
                  <b-input
                    id="vehicle_type"
                    v-model="vehicleIsTransactionValue"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="maintenanceTransaction.approver_level >= 1"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="noted_by_at">
                        <strong>Last Odometer</strong>
                      </label>
                      <b-input
                        id="noted_by_at"
                        v-model="maintenanceTransaction.last_odometer"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="current_odometer">
                        <strong>Current Odometer</strong>
                      </label>
                      <b-input
                        id="current_odometer"
                        v-model="maintenanceTransaction.current_odometer"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="maintenanceTransaction.maintenance_items.length > 0"
                    cols="12"
                  >
                    <label>
                      <strong>Parte o Materyales na Kelangan</strong>
                    </label>
                    <b-table
                      class="mt-1"
                      :fields="tablePartsNeeded.fields"
                      :items="maintenanceTransaction.maintenance_items"
                      responsive
                      striped
                      hover
                    >
                      <template #cell()="data">
                        <div class="text-nowrap">
                          {{ data.value }}
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>

          <!-- Damage Items and Reasons-->
          <b-col
            cols="12"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="damaged_item_specifications">
                    <strong>Ano ang Nasira?</strong>
                  </label>
                  <b-textarea
                    id="damaged_item_specifications"
                    v-model="maintenanceTransaction.damaged_item_specifications"
                    rows="6"
                    max-rows="8"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="cause_of_damage">
                    <strong>Rason ng Pagkasira?</strong>
                  </label>
                  <b-textarea
                    id="cause_of_damage"
                    v-model="maintenanceTransaction.cause_of_damage"
                    rows="6"
                    max-rows="8"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Attachments -->
          <b-col
            v-if="maintenanceTransaction.attachments.length > 0"
            cols="12"
          >
            <label>
              <strong>Attachments</strong>
            </label>
            <b-table
              class="mt-1"
              :fields="tableAttachments.fields"
              :items="maintenanceTransaction.attachments"
              responsive
              striped
              hover
            >
              <template #cell(actions)="row">
                <div class="text-nowrap">
                  <b-button
                    size="sm"
                    class="mr-1"
                    @click="onPreviewAttachment(row.item)"
                  >
                    Preview
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>

          <!-- Signatories -->
          <b-col
            v-if="maintenanceTransaction.status !== 'Cancelled'"
            cols="12"
          >
            <b-row>
              <!-- Approved By -->

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="noted_by_at">
                    <strong>{{ notedByStatus }} At</strong>
                  </label>
                  <b-input
                    id="noted_by_at"
                    v-model="maintenanceTransaction.noted_by_at"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label for="noted_by_name">
                    <strong>{{ notedByStatus }} By</strong>
                  </label>
                  <b-input
                    id="noted_by_name"
                    v-model="maintenanceTransaction.noted_by_name"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="noted_by_remarks">
                    <strong>{{ notedByStatus }} By Remarks</strong>
                  </label>
                  <b-textarea
                    id="noted_by_remarks"
                    v-model="maintenanceTransaction.noted_by_remarks"
                    rows="6"
                    max-rows="8"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="notedByStatus === 'Noted' && maintenanceTransaction.approver_level >= 2"
                cols="12"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="approved_by_at">
                        <strong>{{ approvedByStatus }} At</strong>
                      </label>
                      <b-input
                        id="approved_by_at"
                        v-model="maintenanceTransaction.approved_by_at"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="approved_by_name">
                        <strong>{{ approvedByStatus }} By</strong>
                      </label>
                      <b-input
                        id="approved_by_name"
                        v-model="maintenanceTransaction.approved_by_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <label for="approved_by_remarks">
                        <strong>{{ approvedByStatus }} By Remarks</strong>
                      </label>
                      <b-textarea
                        id="approved_by_remarks"
                        v-model="maintenanceTransaction.approved_by_remarks"
                        rows="6"
                        max-rows="8"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Purchased By -->
              <b-col
                v-if="maintenanceTransaction.funding_type === 'cash-advance' && notedByStatus === 'Noted' && approvedByStatus === 'Approved' && maintenanceTransaction.approver_level >= 3"
                cols="12"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="purchased_by_at">
                        <strong>{{ purchasedByStatus }} At</strong>
                      </label>
                      <b-input
                        id="purchased_by_at"
                        v-model="maintenanceTransaction.purchased_by_at"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="purchased_by_name">
                        <strong>{{ purchasedByStatus }} By</strong>
                      </label>
                      <b-input
                        id="purchased_by_name"
                        v-model="maintenanceTransaction.purchased_by_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <label for="purchased_by_remarks">
                        <strong>{{ purchasedByStatus }} By Remarks</strong>
                      </label>
                      <b-textarea
                        id="purchased_by_remarks"
                        v-model="maintenanceTransaction.purchased_by_remarks"
                        rows="6"
                        max-rows="8"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Completed By -->
              <b-col
                v-if="maintenanceTransaction.status === 'Completed'"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="completed_by_at">
                        <strong>Completed At</strong>
                      </label>
                      <b-input
                        id="completed_by_at"
                        v-model="maintenanceTransaction.completed_by_at"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="completed_by_name">
                        <strong>Completed By</strong>
                      </label>
                      <b-input
                        id="completed_by_name"
                        v-model="maintenanceTransaction.completed_by_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-preview-image"
      size="lg"
      centered
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="`Preview Attachment [${file.name}]`"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.url"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Preview
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-print"
      size="xl"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
    >
      <div style="height: calc(100vh - 220px);">
        <iframe
          style="height: 100%; width: 100%"
          :src="pdfData"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Preview
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SSharedList, SUserMaintenance, SUserAttachment, AxiosError } from '@/services'
import FORMATTER from '@/mixins/formatter'
import HELPER from '@/mixins/helper'
import URLS from '@/mixins/url'

export default {
  name: 'VUserPending',

  middleware: ['auth', 'user'],

  metaInfo: () => ({
    title: 'Pending Requests'
  }),

  mixins: [
    FORMATTER,
    URLS,
    HELPER
  ],

  data () {
    return {
      state: {
        viewing: false,
        busy: false,
        cancelling: false
      },
      list: {
        vehicles: []
      },
      selected: {
        vehicle: null
      },
      file: {
        name: '',
        url: ''
      },
      attachment: {
        path: '',
        files: []
      },
      maintenanceTransaction: {
        id: 0,
        document_number: '',
        actual_amount: 0,
        estimated_amount: 0,
        funding_type: 'petty-cash',
        company_name: '',
        organization_name: '',
        requestor_name: '',
        user_name: '',
        vehicle_name: '',
        vehicle_category_name: '',
        vehicle_license_plate: '',
        vehicle_type: 'owned',
        damaged_item_specifications: '',
        cause_of_damage: '',
        status: 'Processing',
        attachments: [],
        last_odometer: 0,
        current_odometer: 0,
        noted_by_name: '',
        noted_by_remarks: '',
        noted_by_at: '',
        noted_by_status: 'Pending',
        maintenance_items: [],
        approved_by_name: '',
        approved_by_remarks: '',
        approved_by_at: '',
        approved_by_status: 'Pending',
        purchased_by_name: '',
        purchased_by_remarks: '',
        purchased_by_at: '',
        purchased_by_status: 'Pending',
        completed_by_name: '',
        completed_by_at: '',
        approver_level: 0,
        created_at: '',
        updated_at: ''
      },
      maintenance: {
        id: 0,
        requestor_name: '',
        estimated_amount: 0,
        funding_type: 'petty-cash',
        company: null,
        organization: null,
        vehicle: null,
        vehicle_name: '',
        vehicle_type: '',
        vehicle_category: 0,
        vehicle_category_name: '',
        last_odometer: 0,
        current_odometer: 0,
        damaged_item_specifications: '',
        cause_of_damage: '',
        attachments_path: '',
        attachments: [],
        cancellation_remarks: '',
        status: 'Pending'
      },
      tablePending: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'requested at' },
          { key: 'requestor_name' },
          { key: 'vehicle_license_plate', label: 'License Plate' },
          { key: 'vehicle_category_name', label: 'Vehicle Category' },
          { key: 'estimated_amount', label: 'Amount' },
          { key: 'status' },
          { key: 'approver_level', formatter: this.findApprover, label: 'Current Approver' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, label: 'last updated' }
        ]
      },
      tableAttachments: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      },
      tablePartsNeeded: {
        fields: [
          { key: 'name' },
          { key: 'quantity' },
          { key: 'amount' },
          { key: 'funding_type', formatter: this.findFundingType },
          { key: 'supplier_name' },
          { key: 'item_from', formatter: this.findPartsFrom },
          { key: 'item_status', formatter: this.findItemStatus },
          { key: 'last_request', formatter: this.checkLastRequest }
        ]
      },
      pdfData: ''
    }
  },

  computed: {
    modalTitle () {
      return this.state.viewing ? 'View Repair / Maintenance Request' : 'Create Repair / Maintenance Request'
    },

    fundingTypeValue () {
      return this.findFundingType(this.maintenance.funding_type)
    },

    vehicleIsValue () {
      return this.findVehicleIs(this.maintenance.vehicle_type)
    },

    fundingTypeTransactionValue () {
      return this.findFundingType(this.maintenanceTransaction.funding_type)
    },

    vehicleIsTransactionValue () {
      return this.findVehicleIs(this.maintenanceTransaction.vehicle_type)
    },

    notedByStatus () {
      return ['Approved', 'Pending'].includes(this.maintenanceTransaction.noted_by_status) ? 'Noted' : 'Disapproved'
    },

    approvedByStatus () {
      return ['Approved', 'Pending'].includes(this.maintenanceTransaction.approved_by_status) ? 'Approved' : 'Disapproved'
    },

    purchasedByStatus () {
      return ['Approved', 'Pending'].includes(this.maintenanceTransaction.purchased_by_status) ? 'Purchased' : 'Disapproved'
    }
  },

  watch: {
    'maintenance.estimated_amount' (amount) {
      if (amount > 2000) {
        this.maintenance.funding_type = 'cash-advance'
      } else {
        this.maintenance.funding_type = 'petty-cash'
      }
    },
    'selected.vehicle' (row) {
      if (row) {
        this.maintenance.vehicle = row.id
        this.maintenance.vehicle_name = row.name
        this.maintenance.vehicle_category = row.vehicle_category_id
        this.maintenance.vehicle_category_name = row.vehicle_category_name
        this.maintenance.vehicle_type = row.vehicle_type
        this.fetchMaintenanceVehicleHistories(row.id)
      } else {
        this.maintenance.vehicle = 0
        this.maintenance.vehicle_name = ''
        this.maintenance.vehicle_category = 0
        this.maintenance.vehicle_category_name = ''
        this.maintenance.vehicle_type = ''
        this.maintenance.current_odometer = 0
        this.maintenance.last_odometer = 0
      }
    }
  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePending.busy = true

      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      })

      return await SUserMaintenance.get(filters).then(
        ({ data }) => {
          this.tablePending.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tablePending.totalRows = 0
        return []
      }).finally(() => {
        this.tablePending.busy = false
      })
    },

    async getOptions () {
      this.state.busy = true
      return await SSharedList.getVehiclesWithVehicleCategories().then(
        ({ data }) => {
          this.list.vehicles = data
        }
      ).catch(() => {
        this.list.vehicles = []
      }).finally(() => {
        this.state.busy = false
      })
    },

    async fetchMaintenanceVehicleHistories (vehicle) {
      const filters = this.objectToUrl({
        vehicle: vehicle,
        maintenance: 0
      })

      SSharedList.getLastOdometer(filters).then(
        ({ data }) => {
          this.maintenance.last_odometer = data.last_odometer
        }
      ).catch(() => {
        this.maintenance.last_odometer = null
      })
    },

    async onCreate () {
      this.state.viewing = false
      this.state.cancelling = false
      this.maintenance.requestor_name = ''
      this.maintenance.estimated_amount = 0
      this.maintenance.id = 0
      this.maintenance.company = null
      this.maintenance.organization = null
      this.maintenance.vehicle = null
      this.maintenance.vehicle_name = ''
      this.maintenance.vehicle_category = 0
      this.maintenance.vehicle_category_name = ''
      this.maintenance.vehicle_type = ''
      this.maintenance.attachments_path = ''
      this.maintenance.damaged_item_specifications = ''
      this.maintenance.cause_of_damage = ''
      this.maintenance.attachments = []
      this.selected.vehicle = null
      this.maintenance.cancellation_remarks = ''
      this.maintenance.status = 'Pending'

      this.$bvModal.show('modal-maintenance-form')
    },

    async onValidate (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formMaintenance.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: 'Submit this Repair/Maintenance Request?',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Submit Request',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                return this.onPost()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPost () {
      this.state.busy = true
      return new Promise(
        resolve => {
          SUserMaintenance.post(this.maintenance).then(
            ({ data }) => {
              this.$bvModal.hide('modal-maintenance-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.tablePending.refresh()
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formMaintenance.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    onView (row) {
      this.state.viewing = true
      this.state.cancelling = false
      this.maintenanceTransaction.id = row.id
      this.maintenanceTransaction.document_number = row.document_number

      this.maintenanceTransaction.estimated_amount = row.estimated_amount
      this.maintenanceTransaction.actual_amount = row.actual_amount
      this.maintenanceTransaction.funding_type = row.funding_type

      this.maintenanceTransaction.company_name = row.company_name
      this.maintenanceTransaction.organization_name = row.organization_name
      this.maintenanceTransaction.requestor_name = row.requestor_name
      this.maintenanceTransaction.user_name = row.user_name

      this.maintenanceTransaction.vehicle_name = row.vehicle_name
      this.maintenanceTransaction.vehicle_category_name = row.vehicle_category_name
      this.maintenanceTransaction.vehicle_license_plate = row.vehicle_license_plate
      this.maintenanceTransaction.vehicle_type = row.vehicle_type

      this.maintenanceTransaction.damaged_item_specifications = row.damaged_item_specifications
      this.maintenanceTransaction.cause_of_damage = row.cause_of_damage

      this.maintenanceTransaction.attachments_path = row.attachments_path
      this.maintenanceTransaction.attachments = row.attachments

      this.maintenanceTransaction.status = row.status

      this.maintenanceTransaction.last_odometer = row.last_odometer
      this.maintenanceTransaction.current_odometer = row.current_odometer

      this.maintenanceTransaction.noted_by_name = row.noted_by_name
      this.maintenanceTransaction.noted_by_remarks = row.noted_by_remarks
      this.maintenanceTransaction.noted_by_at = row.noted_by_at
      this.maintenanceTransaction.noted_by_status = row.noted_by_status
      this.maintenanceTransaction.maintenance_items = row.maintenance_items

      this.maintenanceTransaction.approved_by_name = row.approved_by_name
      this.maintenanceTransaction.approved_by_remarks = row.approved_by_remarks
      this.maintenanceTransaction.approved_by_at = row.approved_by_at
      this.maintenanceTransaction.approved_by_status = row.approved_by_status

      if (row.funding_type === 'cash-advance') {
        this.maintenanceTransaction.purchased_by_name = row.purchased_by_name
        this.maintenanceTransaction.purchased_by_remarks = row.purchased_by_remarks
        this.maintenanceTransaction.purchased_by_at = row.purchased_by_at
        this.maintenanceTransaction.purchased_by_status = row.purchased_by_status
      }

      this.maintenanceTransaction.approver_level = row.approver_level

      this.maintenanceTransaction.completed_by_name = row.completed_by_name
      this.maintenanceTransaction.completed_by_at = row.completed_by_at

      this.maintenanceTransaction.created_at = this.dateTimeFormatter(row.created_at)
      this.maintenanceTransaction.updated_at = this.dateTimeFormatter(row.updated_at)

      this.$bvModal.show('modal-view-form')
    },

    async onViewCancellation (row) {
      this.state.viewing = true
      this.state.cancelling = true
      this.maintenance.id = row.id
      this.maintenance.estimated_amount = row.estimated_amount
      this.maintenance.company_name = row.company_name
      this.maintenance.organization_name = row.organization_name
      this.maintenance.requestor_name = row.requestor_name
      this.maintenance.vehicle_name = row.vehicle_name
      this.maintenance.vehicle_category_name = row.vehicle_category_name
      this.maintenance.vehicle_license_plate = row.vehicle_license_plate
      this.maintenance.vehicle_category = row.vehicle_category
      this.maintenance.vehicle_type = row.vehicle_type
      this.maintenance.damaged_item_specifications = row.damaged_item_specifications
      this.maintenance.cause_of_damage = row.cause_of_damage
      this.maintenance.attachments_path = row.attachments_path
      this.maintenance.attachments = row.attachments
      this.maintenance.cancellation_remarks = row.cancellation_remarks
      this.maintenance.status = 'Cancelled'
      this.$bvModal.show('modal-maintenance-view-form')
    },

    async onValidateCancellation (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formCancellation.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: 'Mark this Request as Cancelled?',
              confirmButtonColor: '#FF2929',
              confirmButtonText: 'Mark as Cancelled',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                return this.onCancel()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onCancel () {
      this.state.busy = true
      return new Promise(
        resolve => {
          SUserMaintenance.put(this.maintenance).then(
            ({ data }) => {
              this.$bvModal.hide('modal-maintenance-view-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.tablePending.refresh()
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formCancellation.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    async onUploadAttachment () {
      this.state.busy = true

      const formBody = new FormData()

      formBody.append('file_path', this.attachment.path)

      this.attachment.files.forEach((file, i) => {
        formBody.append(`file[${i}]`, file)
      })

      await SUserAttachment.upload(formBody).then(
        ({ data }) => {
          this.maintenance.attachments_path = this.attachment.path = data.file_path
          this.maintenance.attachments.push(...data.file_list)
        }
      ).finally(() => {
        this.attachment.files = []
        this.state.busy = false
      })
    },

    async onRemoveFile (file) {
      this.state.busy = true
      await SUserAttachment.removeFile(this.maintenance.attachments_path, file.file_hash_name).then(() => {
        this.maintenance.attachments = this.maintenance.attachments.filter(
          attachment => attachment.file_hash_name !== file.file_hash_name
        )
      }).finally(() => {
        this.state.busy = false
      })
    },

    async onRemoveAllFile () {
      this.state.busy = true
      await SUserAttachment.removeAllFile(this.maintenance.attachments_path).then(() => {
        this.maintenance.attachments = []
      }).finally(() => {
        this.state.busy = false
      })
    },

    async onPreviewAttachment (file) {
      if (this.state.viewing) {
        this.file.name = file.file_original_name
        if (this.state.cancelling) {
          this.file.url = this.getImageUrl(`${this.maintenance.attachments_path}/${file.file_hash_name}`)
        } else {
          this.file.url = this.getImageUrl(`${this.maintenanceTransaction.attachments_path}/${file.file_hash_name}`)
        }
        this.$bvModal.show('modal-preview-image')
      } else {
        this.file.name = file.file_original_name
        this.file.url = this.getImageUrl(`uploads/${this.maintenance.attachments_path}/${file.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      }
    }
  }
}
</script>
